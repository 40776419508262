export default [
  {
    text: "Actions",
    value: "actions",
    sortable: false,
    align: "left",
    width: "60px",
    order: 0,
    hidable: true,
    hidden: false,
  },
  {
    text: "Type",
    value: "documentType",
    width: "100px",
    order: 1,
    hidable: false,
    hidden: false,
  },
  { text: "Template Name", value: "name", order: 2, hidable: false, hidden: false },
  {
    text: "Doc Name Scheme",
    value: "documentNameScheme",
    sortable: false,
    order: 3,
    hidable: false,
    hidden: false,
  },
  {
    text: "Doc Number Scheme",
    value: "documentNumberScheme",
    sortable: false,
    order: 4,
    hidable: false,
    hidden: false,
  },
  {
    text: "Created",
    value: "createDate",
    width: "110px",
    order: 5,
    hidable: true,
    hidden: true,
  },
  {
    text: "Last Update",
    value: "updateDate",
    width: "130px",
    order: 6,
    hidable: true,
    hidden: false,
  },
  {
    text: "Creator",
    value: "createdBy",
    width: "130px",
    order: 7,
    hidable: true,
    hidden: true,
  },
  {
    text: "Last Updater",
    value: "updatedBy",
    width: "130px",
    order: 8,
    hidable: true,
    hidden: false,
  },
];
